'use client'

import { useListenerToken } from 'authentication'
import { useAsync } from 'react-use'
import { getEntitiesEnums } from '@/features/entity-enums'
import { useMounted, useVersion } from '@/shared/hooks'
import { useEntityEnumsStore } from '@/shared/hooks/services/use-enums'
import { getVersion } from '@/shared/utils'

export const EnumProvider = () => {
  const { version, setVersion } = useVersion()
  const { setEnums, enums } = useEntityEnumsStore()
  const { isMounted } = useMounted()
  const {
    cookies: { access_token },
  } = useListenerToken<{ access_token: string }>()

  const accessToken = access_token
  const currentVersion = getVersion()

  const isEmptyEnums = Object.values(enums).every(
    (enumValue) => !enumValue.length
  )
  const isNotSameVersion = version !== currentVersion

  useAsync(async () => {
    if (!isMounted) return

    if (isNotSameVersion || isEmptyEnums) {
      setVersion(currentVersion)
      console.log({ accessToken })
      if (!accessToken) return

      const { data: enumValues, success } = await getEntitiesEnums(accessToken)

      if (success) setEnums(enumValues)
    }
  }, [isNotSameVersion, accessToken, currentVersion, isMounted, isEmptyEnums])

  return null
}
