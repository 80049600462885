'use client'

import dynamic from 'next/dynamic'
import { Skeleton } from 'ui'

export const DynamicSidebar = dynamic(
  () => import('./sidebar-menu').then((comp) => comp.SideBar),
  {
    ssr: false,
    loading: () => (
      <Skeleton className="h-screen w-[300px] z-50 relative bg-[#353c46] rounded-none" />
    ),
  }
)
