import { useUrlLocation } from '@/shared/hooks'

export const useHandleBackButton = () => {
  const {
    pathname,
    urlParams,
    queriesParams: { previousQuery: previousUrl },
  } = useUrlLocation()

  const hasParams = Boolean(Object.keys(urlParams).length)
  const route = hasParams ? pathname.split('/').at(1) : ''
  const href = previousUrl || `/${route}`

  return {
    states: {
      hasParams,
      route,
      href,
    },
  }
}
