export const PrivateEvents = {
  BalanceChange: 'BALANCE_CHANGE',
  UserIsLocked: 'USER_IS_LOCKED',
  ThreeDSecure: 'THREE_D_SECURE_COMPLETE',
  LanguageChange: 'LANGUAJE_CHANGE',
} as const

export const PublicEvents = {
  ActivePromotion: 'ACTIVE_PROMOTION',
  DeletePromotion: 'DELETE_PROMOTION',
  MovePromotion: 'MOVE_PROMOTION',
  UpdateStoreCategories: 'UPDATE_STORE_CATEGORIES',
  UpdateStoreCart: 'STORE_UPDATE_CART',
} as const
