import { create } from 'zustand'
import { EventResponse } from '../types'

export type EventsState = {
  eventData: EventResponse | null
}

export type EventsActions = {
  setEventData: (eventData: EventResponse | null) => void
}

export const useEventsStore = create<EventsState & EventsActions>((set) => ({
  eventData: null,
  setEventData: (eventData) => set({ eventData }),
}))
