'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import type { NotificationMetaWithUserId } from 'notifications'
import { deleteNotification, useNotificationStore } from 'notifications'
import type { ForwardedRef, MouseEventHandler } from 'react'
import { forwardRef, useEffect, useState, useTransition } from 'react'
import {
  Badge,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'ui'
import { formatDateTimeDDMMYYYY } from '@/shared/utils'
import { ClientRoutes } from '@/shared/routes/client.route'
import { useHandlerTokens } from '@/shared/hooks/utils/use-handler-tokens'
import { PREVIOUS_QUERY_NAME, TAB_QUERY_NAME } from '@/shared/constants'
import { useHandlerResponse } from '@/shared/hooks'
import { throwApiError } from '@/shared/handlers/error.handler'

type AcceptButtonProps = {
  isPending: boolean
  meta?: NotificationMetaWithUserId | null
}
const AcceptButtonWithRef = (
  { isPending, meta }: AcceptButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const pathname = usePathname()

  const defaultButton = (
    <DialogClose asChild>
      <Button disabled={isPending} ref={ref}>
        Aceptar
      </Button>
    </DialogClose>
  )

  if (!meta?.entity) return defaultButton

  // TODO: This should change when the api change the entity value for providers entity
  if (meta.entity === 'STORE_PROVIDERS') {
    return (
      <DialogClose asChild>
        <Button asChild disabled={isPending} ref={ref}>
          <Link href={ClientRoutes.Providers}>Deshabilitar proveedor</Link>
        </Button>
      </DialogClose>
    )
  }

  if (meta.userId) {
    const url = new URL(
      `${ClientRoutes.Users}/${meta.userId}`,
      window.location.origin
    )

    if (!pathname.includes(ClientRoutes.Users)) {
      url.searchParams.append(PREVIOUS_QUERY_NAME, pathname)
    } else {
      url.searchParams.delete(PREVIOUS_QUERY_NAME)
    }

    if (meta.entity !== 'users') {
      url.searchParams.append(TAB_QUERY_NAME, meta.entity)
    }

    const href = url.toString()
    const isSameRoute = pathname === href
    const handleDisableTopLoader: MouseEventHandler<HTMLAnchorElement> = (
      e
    ) => {
      e.preventDefault()
      e.nativeEvent.stopImmediatePropagation()
    }
    const handleClick = isSameRoute ? handleDisableTopLoader : undefined

    return (
      <DialogClose asChild>
        <Button asChild disabled={isPending} ref={ref}>
          <Link href={href} onClick={handleClick}>
            Detalles
          </Link>
        </Button>
      </DialogClose>
    )
  }

  const entityPage = Object.entries(ClientRoutes).find(
    ([routeKey]) => routeKey.toLowerCase() === meta.entity.toLowerCase()
  )?.[1]

  if (entityPage) {
    return (
      <DialogClose asChild>
        <Button asChild disabled={isPending} ref={ref}>
          <Link href={entityPage}>Aceptar</Link>
        </Button>
      </DialogClose>
    )
  }

  return defaultButton
}

const AcceptButton = forwardRef(AcceptButtonWithRef)

export const NotificationDialog = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const { notification, setNotification } = useNotificationStore()
  const [isPending, startTransition] = useTransition()
  const { getAccessToken } = useHandlerTokens()
  const { handlerError, handlerSuccess } = useHandlerResponse()

  useEffect(() => {
    if (notification) {
      setOpenDialog(true)
    }
  }, [notification])

  const handleOpenDialog = (open: boolean) => {
    if (isPending) return
    if (!notification) return

    if (!open) {
      setNotification(null)
    }
    setOpenDialog(open)
  }

  if (!notification) {
    return null
  }

  const handleDeleteNotification = () => {
    startTransition(async () => {
      try {
        if (!notification.id) return

        const accessToken = await getAccessToken()

        if (!accessToken) return

        const { success, errors, message, statusCode } =
          await deleteNotification({
            accessToken,
            notificationId: notification.id,
            appHeader: 'panel',
            locale: 'es',
          })

        if (!success) throwApiError({ errors, message, statusCode })

        setNotification(null)
        setOpenDialog(false)
        handlerSuccess({ message })
      } catch (error) {
        handlerError({ error })
      }
    })
  }

  const { body, title, createdAt, meta } = notification

  return (
    <Dialog modal onOpenChange={handleOpenDialog} open={openDialog}>
      <DialogContent showCloseButton>
        {!!title && (
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
        )}

        <p>{body}</p>
        {!!createdAt && (
          <p>
            <b>Fecha: </b>
            <Badge className="inline-block w-max">
              {formatDateTimeDDMMYYYY(createdAt)}
            </Badge>
          </p>
        )}

        <DialogFooter className="mt-4">
          <Button
            isLoading={isPending}
            onClick={handleDeleteNotification}
            variant="destructive"
          >
            Eliminar
          </Button>

          <AcceptButton
            isPending={isPending}
            meta={meta as NotificationMetaWithUserId}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
