'use client'

import { useEffect } from 'react'
import { useMounted, useVersion } from '@/shared/hooks'
import { getVersion } from '@/shared/utils'

export const VersionProvider = () => {
  const { isMounted } = useMounted()
  const { version, setVersion } = useVersion()
  const currentVersion = getVersion()

  const isNotSameVersion = version !== currentVersion

  useEffect(() => {
    if (!isMounted) return

    if (isNotSameVersion) {
      localStorage.clear()
      setVersion(currentVersion)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVersion, isMounted, isNotSameVersion])

  return null
}
