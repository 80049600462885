'use client'

import { es } from 'date-fns/locale'
import {
  NotificationDropdown,
  NotificationInfoBlock,
  useNotificationCountStore,
  useNotificationStore,
} from 'notifications'
import { useHandlerTokens } from '@/shared/hooks/utils/use-handler-tokens'

export const NotificationDropdownMenu = () => {
  const { getAccessToken } = useHandlerTokens()
  const setNotification = useNotificationStore((state) => state.setNotification)
  const notificationCountResponse = useNotificationCountStore(
    (state) => state.notificationCountResponse
  )

  return (
    <NotificationDropdown
      appHeader="panel"
      getAccessToken={getAccessToken}
      key={JSON.stringify(notificationCountResponse)}
      loadMoreButtonText="Mostrar más"
      noNotificationsText="No hay notificaciones para mostrar"
      notificationCount={notificationCountResponse}
    >
      {({
        notifications,
        handleDeleteNotification,
        handleOpenNotification,
      }) => (
        <>
          {notifications.map((notification) => (
            <NotificationInfoBlock
              handleDeleteNotification={handleDeleteNotification}
              handleOpenNotification={handleOpenNotification}
              key={notification.id}
              locale={es}
              notification={notification}
              setNotification={setNotification}
            />
          ))}
        </>
      )}
    </NotificationDropdown>
  )
}
