'use client'

import dynamic from 'next/dynamic'
import { Skeleton } from 'ui'

export const DynamicMenu = dynamic(
  () => import('./menu').then((comp) => comp.Menu),
  {
    ssr: false,
    loading: () => <Skeleton className="h-4 w-[180px]" />,
  }
)
