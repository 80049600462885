'use client'

import { ArrowLeft } from 'lucide-react'
import Link from 'next/link'
import { Button } from 'ui'
import { useHandleBackButton } from './use-handle-back-button'

export const BackButton = () => {
  const {
    states: { hasParams, href, route },
  } = useHandleBackButton()

  if (!(hasParams && route)) return null

  return (
    <Button
      asChild
      className="rounded-full w-7 h-7"
      size="icon"
      variant="ghost"
    >
      <Link href={href}>
        <ArrowLeft size={16} />
      </Link>
    </Button>
  )
}
