'use client'

import { Button, NotificationIcon } from 'ui'
import { useFetchNotificationCount } from '@/shared/hooks/services/use-fetch-notification-count'
import { NotificationDropdownMenu } from './notification-dropdown-menu'

export const NotificationMenu = () => {
  const {
    states: { loading },
  } = useFetchNotificationCount()

  if (loading)
    return (
      <Button className="relative font-normal" size="icon" variant="ghost">
        <NotificationIcon />
      </Button>
    )

  return <NotificationDropdownMenu />
}
