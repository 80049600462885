import(/* webpackMode: "eager", webpackExports: ["BackButton"] */ "/app/apps/panel/src/components/header/back-button/back-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DynamicMenu"] */ "/app/apps/panel/src/components/header/menu/dynamic-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationMenu"] */ "/app/apps/panel/src/components/header/notifications/notification-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LogoutDialogLoader"] */ "/app/apps/panel/src/components/logout-dialog-loader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationDialog"] */ "/app/apps/panel/src/components/notification-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DynamicEventsProvider"] */ "/app/apps/panel/src/components/providers/dynamic-events-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/panel/src/components/providers/enum-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/panel/src/components/providers/events-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/panel/src/components/providers/push-notification-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/panel/src/components/providers/version-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DynamicSidebar"] */ "/app/apps/panel/src/components/sidebar/dynamic-sidebar.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/accordion/accordion.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/alert-dialog/alert-dialog.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/alert/alert-destructive.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/avatar/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/app/packages/ui/src/components/button/button.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/calendar/calendar.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/carousel/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/checkbox/checkbox.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/command/command.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/cuban-phone-input/cuban-phone-input.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/custom-select/custom-select.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/datepicker/datepicker.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/dialog/dialog.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/drawer/drawer.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/dropdown-menu/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/form/form.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/hover-card/hover-card.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/input-color-picker/input-color-picker.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/input/input.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/label/label.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/loader/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/menubar/menubar.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/navigation-menu/navigation-menu.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/phone-input/phone-input.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/popover/popover.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/radiogroup/radiogroup.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/scroll-area/scroll-area.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/select/select.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/separator/separator.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/slider/slider.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/stepper/stepper.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/switch/switch.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/tabs/tabs.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/textarea/textarea.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/toast/toast.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/toast/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/tooltip/tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/components/usa-phone-input/usa-phone-input.tsx");
