'use client'

import { Dialog, DialogContent } from 'ui'
import { useShowLogoutLoader } from '@/shared/hooks/utils/use-show-logout-loader'
import { GlobalLoader } from './global-loader'

export const LogoutDialogLoader = () => {
  const open = useShowLogoutLoader((state) => state.open)

  return (
    <Dialog open={open}>
      <DialogContent className="bg-transparent border-none shadow-none">
        <GlobalLoader />
      </DialogContent>
    </Dialog>
  )
}
