'use client'

import { useListenerToken } from 'authentication'
import { type Events, PrivateEvents, useSubscribeEvent } from 'api-events'
import { useAsync } from 'react-use'
import type { FC } from 'react'
import { useLogout } from '@/shared/hooks'

export const EventsProvider = () => {
  const {
    cookies: { access_token },
  } = useListenerToken<{ access_token: string }>()

  const accessToken = access_token

  if (!accessToken) return null

  return <EventHandler accessToken={accessToken} key={accessToken} />
}

const EventHandler: FC<{ accessToken?: string }> = ({ accessToken }) => {
  const { handleLogout } = useLogout()
  const { eventData } = useSubscribeEvent({
    accessToken,
    appHeader: 'panel',
  })

  useAsync(async () => {
    if (!eventData || eventData.data.event !== PrivateEvents.UserIsLocked)
      return

    const eventLookup: Partial<Record<Events, () => Promise<void>>> = {
      USER_IS_LOCKED: handleLogout,
    }
    await eventLookup[eventData.data.event]?.()
  }, [eventData])

  return null
}
