'use client'

import { apiBaseUrl, decodeAccessToken } from 'libs'
import { useEffect, useMemo, useRef } from 'react'
import { io } from 'socket.io-client'
import { SubscribeEventHookProps } from '../types'
import { useEventsStore } from '../store/events.store'

export const useSubscribeEvent = ({
  appHeader = 'web',
  accessToken,
}: SubscribeEventHookProps) => {
  const url = apiBaseUrl ?? ''
  const flag = useRef(true)

  const { eventData, setEventData } = useEventsStore((state) => ({
    eventData: state.eventData,
    setEventData: state.setEventData,
  }))

  const socket = useMemo(() => {
    return io(url, {
      reconnection: false,
      autoConnect: false,
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
        app: appHeader,
      },
    })
  }, [url, accessToken, appHeader])

  useEffect(() => {
    if (!flag.current) return
    socket.connect()

    return () => {
      socket.disconnect()
      flag.current = false
    }
  }, [])

  useEffect(() => {
    let userId: number | undefined

    if (accessToken) {
      const decodedToken = decodeAccessToken(accessToken)
      userId = decodedToken.userId

      socket.on(`private_${appHeader}_${userId}`, (data) => {
        setEventData(data)
      })
    }

    socket.on(`public_${appHeader}`, (data) => {
      setEventData(data)
    })

    return () => {
      socket.off(`private_${appHeader}_${userId}`)
      socket.off(`public_${appHeader}`)
    }
  }, [accessToken])

  return {
    eventData,
  }
}
