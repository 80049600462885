import type { AdditionalBaseResponse } from 'libs'
import { isSuccess, request } from 'libs'
import { ApiRoutes } from '@/shared/routes/api.routes'
import { getHeaders } from '@/shared/utils'
import type { EntityEnums } from './types'

// @note this service is for get the enums that the entities use

export const getEntitiesEnums = async (
  accessToken?: string
): Promise<AdditionalBaseResponse<{ data: EntityEnums }>> => {
  const response = await request.get(ApiRoutes.EntityEnums, {
    headers: getHeaders({ accessToken }),
  })

  const data = await response.json()
  return {
    data,
    statusCode: response.status,
    success: isSuccess(response.status),
  }
}
